import * as React from "react"
import Container from "../microcomponents/container/container"
import { Link } from "gatsby"
import {
  ArrowIcon,
  CrossIcon,
  InputErrorIcon,
  InputValidIcon,
  SPZLOGOWHITE,
} from "../microcomponents/icons"
import Button from "../microcomponents/button/button"
import SahilPatelLp from "../componentsv2/sahil-patel-lp/sahil-patel-lp"
import { Field, Form, Formik } from "formik"
import SEO from "../components/seo"
const SLACK_WEBHOOK_URL =
  "https://hooks.slack.com/services/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"

const SahilPatel = ({ version = "v2" }) => {
  const [bookSahil, setBookSahil] = React.useState(false)

  React.useEffect(() => {
    if (bookSahil) {
      document.querySelector("body").style.overflow = "hidden"
    } else {
      document.querySelector("body").style.overflow = "auto"
    }
  }, [bookSahil])

  const handleScroll = () => {
    if (window.scrollY < 30) {
      if (document.querySelector(".header-wrapper")) {
        document.querySelector(".header-wrapper").classList.remove("sticky")
      }
    } else {
      if (document.querySelector(".header-wrapper")) {
        document.querySelector(".header-wrapper").classList.add("sticky")
      }
    }
  }

  const formMessageEnableDisable = () => {
    setTimeout(() => {
      document.querySelector(".form-lp .get-demo-form.fmwp").style.display =
        "none"
      document.querySelector(".form-lp .get-demo-form.tkwp").style.display =
        "block"
    }, 400)
  }

  const allValid = closestInputWrap => {
    closestInputWrap.classList.remove("error")
    closestInputWrap.classList.remove("focus")
    closestInputWrap.classList.add("valid")
  }

  const allError = closestInputWrap => {
    closestInputWrap.classList.add("error")
    closestInputWrap.classList.remove("focus")
    closestInputWrap.classList.remove("valid")
  }

  const handleBlur = (closestInputWrap, condition) => {
    if (!condition) {
      allError(closestInputWrap)
      return
    } else {
      allValid(closestInputWrap)
      return true
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, false)
    return () => window.removeEventListener("scroll", handleScroll, false)
  }, [])

  return (
    <div>
      <SEO
        title="CRO Crimes Gameshow by Sahil Patel of Spiralyze"
        showDefaultTitle={false}
        description="Book Sahil Patel's CRO Crimes Gameshow for your company event. An engaging, interactive challenge that entertains and educates."
      />
      {bookSahil && (
        <Container
          className="get-demo-wrapper no-image form-lp"
          // onClick={e => {
          //   if (e.target.classList.contains("get-demo-wrapper")) {
          //     setBookSahil(false)
          //   }
          // }}
        >
          <div className="get-demo-inner">
            <div className="get-demo-card">
              <div
                className="close-modal-lp"
                onClick={() => setBookSahil(false)}
              >
                <CrossIcon />
              </div>
              <div className="get-demo-form fmwp">
                <h1>Book Sahil</h1>
                <div className="get-demo-form-wrapper">
                  <form
                    className="form"
                    name="get-demo-form-v2"
                    onSubmit={async e => {
                      e.preventDefault()
                      // const { name, email, eventName } = e.target.elements
                      // if (!name.value || !email.value || !eventName.value) {
                      //   return
                      // }
                      const formData = {
                        name: e.target.elements.name.value,
                        email: e.target.elements.email.value,
                        eventName: e.target.elements.eventName.value,
                      }
                      if (!formData.name) {
                        allError(e.target.elements.name.closest(".input-wrap"))
                        return
                      }
                      if (
                        !formData.email ||
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          formData.email
                        )
                      ) {
                        allError(e.target.elements.email.closest(".input-wrap"))
                        return
                      }
                      if (!formData.eventName) {
                        allError(
                          e.target.elements.eventName.closest(".input-wrap")
                        )
                        return
                      }
                      const response = await fetch("/api/sendToSlack", {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(formData),
                      })

                      if (response.ok) {
                        formMessageEnableDisable()
                      } else {
                        console.error("Failed to send message to Slack")
                      }
                    }}
                  >
                    <div className={`input-wrap`}>
                      <label>
                        {" "}
                        Name
                        {<InputErrorIcon />}
                        {<InputValidIcon />}
                      </label>
                      <input
                        name="name"
                        placeholder="Name"
                        className="text-input"
                        onFocus={e => {
                          e.currentTarget
                            .closest(".input-wrap")
                            .classList.add("focus")
                        }}
                        onBlur={e => {
                          handleBlur(
                            e.currentTarget.closest(".input-wrap"),
                            e.currentTarget.value.trim() !== ""
                          )
                        }}
                      />
                    </div>
                    <div className={`input-wrap`}>
                      <label>
                        {" "}
                        Email
                        {<InputErrorIcon />}
                        {<InputValidIcon />}
                      </label>
                      <input
                        type="text"
                        name="email"
                        className="text-input"
                        placeholder="Email Address"
                        onFocus={e => {
                          e.currentTarget
                            .closest(".input-wrap")
                            .classList.add("focus")
                        }}
                        onBlur={e => {
                          handleBlur(
                            e.currentTarget.closest(".input-wrap"),
                            e.currentTarget.value.trim() !== "" &&
                              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                e.currentTarget.value
                              )
                          )
                        }}
                      />
                    </div>
                    <div className={`input-wrap`}>
                      <label>
                        Event Name
                        {<InputErrorIcon />}
                        {<InputValidIcon />}
                      </label>
                      <input
                        type="text"
                        name="eventName"
                        className="text-input"
                        placeholder="Event Name"
                        onFocus={e => {
                          e.currentTarget
                            .closest(".input-wrap")
                            .classList.add("focus")
                        }}
                        onBlur={e => {
                          handleBlur(
                            e.currentTarget.closest(".input-wrap"),
                            e.currentTarget.value.trim() !== ""
                          )
                        }}
                      />
                    </div>
                    <Button
                      version={`v2`}
                      icon={<ArrowIcon />}
                      className="btn-component"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </form>
                </div>
              </div>
              <div className="get-demo-form tkwp">
                <h2>Your request has been sent!</h2>
                <p>
                  We appreciate you contacting us. One of our colleagues <br />{" "}
                  will get back in touch with you soon!
                </p>
                <br />
                <div className="get-demo-form-wrapper">
                  <Button
                    version={`v2`}
                    onClick={() => setBookSahil(false)}
                    icon={null}
                    type="cta"
                  >
                    Back to main page
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}
      <Container className={`header-wrapper ${version} sahil-patel-lp`}>
        <div className="header">
          <Link to={`/`} className="header-logo">
            <SPZLOGOWHITE />
          </Link>

          <div className={`header-links desktop`}>
            <ul className={"nav nav-list"}>
              <li className=" nav-list-item howitworks submenu">
                <a
                  className="nav-link"
                  style={{
                    opacity: 0,
                    pointerEvents: "none",
                  }}
                >
                  ----
                </a>
              </li>
            </ul>
          </div>
          <div className="header-cta">
            <Button
              className="btn-component nav-link get-demo"
              type={`cta`}
              version={version}
              onClick={() => setBookSahil(true)}
              icon={<ArrowIcon />}
            >
              Book Sahil
            </Button>
          </div>
        </div>
      </Container>
      <SahilPatelLp bookSahil={bookSahil} setBookSahil={setBookSahil} />
    </div>
  )
}

export default SahilPatel
