import React from "react"
import Container from "../../microcomponents/container/container"
import "./sahil-patel-lp.scss"
import Hero from "../../microcomponents/hero/hero"
import HtmlParser from "react-html-parser"
import {
  LinkedInIcon2,
  PlayIconLp,
  SpiralLogoBg,
  TestimonialQuoteIcon,
} from "../../microcomponents/icons"
import Image from "../../components/image-component/image-2023"
import HeadSubhead from "../../microcomponents/headsubhead/headsubhead"
import Modal from "../../components/modal/modal"
import YouTube from "react-youtube"
const testimonials = [
  {
    image: "mattheinz.webp",
    name: "Matt Heinz",
    designation:
      "Co-host, CMO Coffee Talk <span><br/> a community of 4,000 CMOs</span>",
    testimonial:
      "CRO Crimes is unlike any other show I've been on. It's crazy. Unpredictable. Amazing. You'll love it.",
  },
  {
    image: "drewniesser.webp",
    name: "Drew Neisser",
    designation:
      "Host, CMO Super Huddle <span><br/> Palo Alto - November 7, 2024 </span>",
    testimonial:
      "CRO Crimes [Sahil's gameshow] is a crowd pleaser and a great way to add variety to your event programming. Our attendees loved it.",
  },
]

const pastGuests = [
  {
    image: "kylelacy.webp",
    name: "Kyle Lacy",
    designation: "Chief Marketing Officer, Jellyfish",
    other: "",
  },
  {
    image: "mattheinzp.webp",
    name: "Matt Heinz",
    designation: "Founder / President, HeinzMarketing",
    other: "Co-host of CMO Coffee Talk, a community of 4,000 CMOs",
  },
  {
    image: "tanyalittlefieldp.webp",
    name: "Tanya Littlefield",
    designation: "VP, Growth & Digital Marketing, Quickbase",
    other: "",
  },
]
export default function SahilPatelLp({ bookSahil, setBookSahil }) {
  return (
    <>
      <Hero
        version="v2"
        className={`sahil-patel-lp `}
        subHeading="B2B Edu-tainment"
        description="
          <ul class='lp-list'>
          <li>
            <span>Unscripted:</span> Skip the predictable PowerPoints—CRO Crimes delivers an interactive experience your audience won’t forget.
          </li>
          <li>
            <span>Fan Favorite:</span> On-stage guests team up with Sahil to tackle real B2B website challenges in a live, interactive showdown.
          </li>
          <li>
            <span>Fast-Paced:</span> High-energy, rapid-fire rounds keep the audience engaged, with guessing, laughter, and prizes along the way.
          </li>
          </ul>
          "
        ctaAction={() => setBookSahil(true)}
        heroAltText="Sahil Patel"
        heading="Hire <span>Sahil Patel</span> and his B2B gameshow for your next event"
        heroImage={`Sahil-Vertical-Center-WhiteBG12.webp`}
        sourceFolder={`sahilpatellp`}
        cloudinaryPath={`https://res.cloudinary.com/spiralyze/image/upload/f_auto/spiralyzehomepage`}
        ctaText="Book Sahil"
      />
      <Container
        className={`testimonials-lp-wrapper sahil-patel-lp gray-bg pd-112-top pd-112-bottom`}
      >
        <div className="testimonials-lp-inner d-flex">
          {testimonials.map((testimonial, index) => {
            return (
              <div key={index}>
                <Image
                  lazyLoad={"true"}
                  fallBackImage={testimonial.image}
                  alt={testimonial.name}
                  imgName={testimonial.image}
                  sourceFolder={`sahilpatellp`}
                  cloudinaryPath={`https://res.cloudinary.com/spiralyze/image/upload/f_auto/spiralyzehomepage`}
                />
                <h3> {testimonial.name}</h3>
                <h4>{HtmlParser(testimonial.designation || "")}</h4>
                <div>
                  <TestimonialQuoteIcon />
                </div>

                <p>{testimonial.testimonial}</p>
              </div>
            )
          })}
        </div>
      </Container>
      <Container
        className={`howitworks-lp-wrapper cro-crimes-in-action sahil-patel-lp pd-112-top pd-112-bottom`}
      >
        <div className="howitworks-lp-inner d-flex justify-between">
          <div className="video-wrapper">
            <Modal
              popupClass="cro-crimes-video"
              modalButton={
                <>
                  <Image
                    className="video zoomonhover"
                    lazyLoad={"true"}
                    fallBackImage={`sahilcrocrimesinactionnew.webp`}
                    imgName={`sahilcrocrimesinactionnew.webp`}
                    alt={`Sahil Patel's CRO Crimes in Action`}
                    sourceFolder={`sahilpatellp`}
                    cloudinaryPath={`https://res.cloudinary.com/spiralyze/image/upload/f_auto/spiralyzehomepage`}
                  />
                  <div className="cro-crimes-cta">
                    <PlayIconLp />
                  </div>
                </>
              }
              modalContent={
                <div className="iframe-wrap">
                  <YouTube
                    videoId={`bAYKrHI25Xg`}
                    onReady={event => {
                      event.target.playVideo()
                    }}
                  />
                </div>
              }
            />
          </div>
          <div className="d-flex flex-column justify-end">
            <HeadSubhead
              version={`v2 darktext`}
              heading={`<span>See CRO Crimes in Action</span>`}
            ></HeadSubhead>
            <p>
              “It's always a hoot when we play buzzword bingo. Half the audience
              is secretly checking their own website to make sure they're not
              guilty!"
            </p>
            <h3>- Sahil Patel </h3>
          </div>
        </div>
      </Container>
      <Container
        className={`howitworks-lp-wrapper sahil-patel-lp blue-bg blueeclipse howitworkssection pd-112-top pd-112-bottom`}
      >
        <div className="spz-logo-pattern">
          <SpiralLogoBg />
        </div>

        <div className="howitworks-lp-inner d-flex">
          <div>
            <HeadSubhead
              version={`v2 litetext`}
              heading={`<span>How it works</span>`}
            ></HeadSubhead>
          </div>
          <div>
            <ul className="lp-list litetext">
              <li>
                <span> Sahil hosts.</span>
              </li>
              <li>
                <span>3 contestants</span> on stage, each wearing noise
                canceling headsets. Can be anyone from the audience.
              </li>
              <li>
                <span>Contestants play 5 rounds</span> — the winner of each
                round gets a point.
              </li>
              <li>
                <span> Sahil shows different websites</span>, contestants try to
                spot the key mistakes that hurt conversion rates.{" "}
                <i>
                  For example, in Fake Headline, we show 4 homepages with
                  jargon-filled headlines. 3 are real, 1 is fake. Contestants
                  try to guess which one is fake.
                </i>
              </li>
              <li>
                <span>Audience is encouraged</span> to shout their answers.
              </li>
              <li>
                <span>Sahil adds commentary</span> and jokes, drawing on his
                conversion rate optimization expertise.
              </li>
            </ul>
          </div>
        </div>
      </Container>

      <Container
        className={`crimesinaction-lp-wrapper sahil-patel-lp pd-112-bottom pd-112-top`}
      >
        <div>
          <HeadSubhead
            version={`v2`}
            heading={`<span>Past Guests</span>`}
            //   subheading={`170 people. 16 countries. Headquartered in Atlanta, Georgia.<br class='hidemobile'/> Alumni from McKinsey, Salesforce, and Facebook.<br class='hidemobile'/> Obsessively focused on conversion.`}
          ></HeadSubhead>
        </div>
        <div className="crimesinaction-lp-inner d-flex">
          {pastGuests.map((pg, index) => {
            return (
              <div key={index}>
                <Image
                  lazyLoad={"true"}
                  alt={pg.name}
                  fallBackImage={pg.image}
                  imgName={pg.image}
                  sourceFolder={`sahilpatellp`}
                  cloudinaryPath={`https://res.cloudinary.com/spiralyze/image/upload/f_auto/spiralyzehomepage`}
                />
                <h3> {pg.name}</h3>
                <span>{HtmlParser(pg.designation || "")}</span>
                <p>{pg.other}</p>
              </div>
            )
          })}
        </div>
      </Container>
      <Container
        className={`howitworks-lp-wrapper cro-crimes sahil-patel-lp gray-bg pd-112-top pd-112-bottom`}
      >
        <div className="howitworks-lp-inner d-flex justify-between">
          <div>
            <HeadSubhead
              version={`v2 darktext`}
              heading={`<span>Sahil delivers high-energy <span style="white-space:nowrap">edu-tainment</span></span>`}
            ></HeadSubhead>
            <Image
              lazyLoad={"true"}
              fallBackImage={`sahilincrocrimes`}
              imgName={`sahilincrocrimes`}
              alt={`Sahil Patel`}
              sourceFolder={`sahilpatellp`}
              cloudinaryPath={`https://res.cloudinary.com/spiralyze/image/upload/f_auto/spiralyzehomepage`}
            />
          </div>
          <div>
            <h3>Sahil Patel</h3>
            <ul className="lp-list darktext">
              <li>
                <span> Energetic speaker.</span>
              </li>
              <li>
                <span>Real expertise</span> in B2B SaaS. Has worked with brands
                like CrowdStrike, Angi, PipeDrive and Deel.
              </li>
              <li>
                <span>No boring</span> talks about himself
              </li>
              <li>
                <span>Not trying</span> to sell you his book (he doesn't even
                have a book)
              </li>
            </ul>

            <h3 className="pd-40-top">CRO Crimes</h3>

            <ul className="lp-list darktext">
              <li>
                <span>Gameshow format. </span>Not death by powerpoint.
              </li>
              <li>
                <span>Unscripted. </span> Every time is unique based on the
                contestants answers.
              </li>
              <li>
                <span>Interactive.</span> Contestants rapid-fire their answers
                while looking website home pages and finding felonies that hurt
                conversion rates.
              </li>
              <li>
                <span> Fun stakes. </span>The winner gets $100 for their
                charity.
              </li>
            </ul>
          </div>
        </div>
      </Container>
      <Container
        className={`howitworks-lp-wrapper cro-crimes recent-speaking sahil-patel-lp blue-bg blueeclipse pd-112-top`}
      >
        <div className="howitworks-lp-inner d-flex justify-between d-flex-row-reverse">
          <div>
            <Image
              lazyLoad={"true"}
              fallBackImage={`recentspeaking.webp`}
              imgName={`recentspeaking.webp`}
              alt={`Recent Speaking Engagement`}
              sourceFolder={`sahilpatellp`}
              cloudinaryPath={`https://res.cloudinary.com/spiralyze/image/upload/f_auto/spiralyzehomepage`}
            />
          </div>
          <div className="d-flex flex-column justify-center">
            <HeadSubhead
              version={`v2 litetext`}
              heading={`<span>Recent Speaking <br/> Engagement</span>`}
            ></HeadSubhead>
            <ul className="lp-list litetext">
              <li>Nov 7, 2024</li>
              <li>CMO Super Huddle, Palo Alto, CA</li>
              <li>3 contestants vying $100 for their charity</li>
              <li>120+ Chief Marketing Officers</li>
            </ul>
          </div>
        </div>
      </Container>
      <Container
        className={`howitworks-lp-wrapper cro-crimes recent-speaking sahil-patel-lp blue-bg pd-112-top pd-112-bottom`}
      >
        <div className="d-flex align-center justify-center">
          <a
            className="follow-speaker"
            href="https://www.linkedin.com/in/sahilanamipatel/"
            target="_blank"
          >
            <LinkedInIcon2 /> Follow Sahil on LinkedIn
          </a>
        </div>
      </Container>
    </>
  )
}
